import { Flex, useStyleConfig } from '@chakra-ui/react'
import { constants } from 'lib/constants'
import { SliderContainer } from './new-shape-selector'
import { GeometryState, WebsiteState } from 'lib/state'
/*
 * Just a slider to resize the currently selected sphere
 */

interface SphereScalerProps {
  geometryState: GeometryState
  websiteState: WebsiteState
}

export default function SphereScaler({
  geometryState,
  websiteState,
}: SphereScalerProps) {
  const pickerStyles = useStyleConfig('FieldBox', { variant: 'pickerInput' })
  const [spline, setSpline] = [geometryState.spline, geometryState.setSpline]

  const onRadiusChange = (value: number) => {
    const newSpheres = [...geometryState.spheres]
    newSpheres[websiteState.selectedSphere].radius = value * 10

    geometryState.setSpheres(newSpheres, false)
  }

  const onUChange = (value: number) => {
    const newSpheres = [...geometryState.spheres]
    newSpheres[websiteState.selectedSphere].u = value

    geometryState.setSpheres(newSpheres, false)
  }

  const onVChange = (value: number) => {
    const newSpheres = [...geometryState.spheres]
    newSpheres[websiteState.selectedSphere].v = value

    geometryState.setSpheres(newSpheres, false)
  }

  if (geometryState.spheres[websiteState.selectedSphere])
    return (
      <Flex direction={'column'} alignItems={'center'} width={'100%'}>
        <Flex width={'80%'} marginTop={'1em'}>
          BUBBLE
        </Flex>
        <Flex direction={'column'} alignItems={'center'} width={'80%'}>
          <SliderContainer
            geometryState={geometryState}
            modelBeingUpdated={websiteState.modelBeingUpdated}
            setModelBeingUpdated={websiteState.setModelBeingUpdated}
            numerical={websiteState.numericalMode}
            defaultvalue={4}
            pickerstyles={pickerStyles}
            text={'SIZE'}
            min={2}
            max={10}
            value={
              geometryState.spheres[websiteState.selectedSphere].radius / 10
            }
            onChange={onRadiusChange}
            step={0.1}
          />
          <SliderContainer
            geometryState={geometryState}
            modelBeingUpdated={websiteState.modelBeingUpdated}
            setModelBeingUpdated={websiteState.setModelBeingUpdated}
            numerical={websiteState.numericalMode}
            defaultvalue={0.5}
            pickerstyles={pickerStyles}
            text={'HORIZONTAL'}
            min={0}
            max={1}
            value={geometryState.spheres[websiteState.selectedSphere].u}
            onChange={onUChange}
            step={0.01}
          />
          <SliderContainer
            geometryState={geometryState}
            modelBeingUpdated={websiteState.modelBeingUpdated}
            setModelBeingUpdated={websiteState.setModelBeingUpdated}
            numerical={websiteState.numericalMode}
            defaultvalue={0.5}
            pickerstyles={pickerStyles}
            text={'VERTICAL'}
            min={0}
            max={1}
            value={geometryState.spheres[websiteState.selectedSphere].v}
            onChange={onVChange}
            step={0.01}
          />
        </Flex>
      </Flex>
    )
  else return <></>
}
