import { useSize } from '@chakra-ui/react-use-size'
import { MutableRefObject, useRef, useState } from 'react'
import {
  AspectRatio,
  Flex,
  Tooltip,
  useColorModeValue,
  useStyleConfig,
} from '@chakra-ui/react'
import { modelTypes } from 'lib/constants'

import { IneShape } from 'lib/mesh-maker'
import { IneKnot, IneSpline } from 'lib/spline-classes'
import { getProfile } from './new-shape-selector'
import { GeometryState, WebsiteState } from 'lib/state'
import { Vector2, Vector3 } from 'three'

interface SketchEditorProps {
  geometryState: GeometryState
  websiteState: WebsiteState
}

export default function SketchEditor({
  geometryState,
  websiteState,
}: SketchEditorProps) {
  const [profiles, setProfiles] = [
    geometryState.profiles,
    geometryState.setProfiles,
  ]
  const [spline, updateSpline] = useState<IneSpline | undefined>(
    (getProfile(1, profiles) as IneShape).spline
  )

  const editorType = geometryState.modelType.editorType

  const printBedWidthToHeightRatio =
    editorType.getSmallerXYDimension() / editorType.printerType.dimensions.z

  const socketType = geometryState.modelType
  const socketSize = socketType.socketSize

  const editorRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const editorSize = useSize(editorRef)

  const flipped = socketType.id == modelTypes.leroyMerlin.id

  function setSpline(newSpline: IneSpline) {
    updateSpline(newSpline)
    setProfiles(
      {
        startProfile: new IneShape(newSpline, 0, 0),
        endProfile: new IneShape(newSpline, 1, 0),
        intermediateShapes: [],
      },
      false
    )
  }

  const containerStyle = useStyleConfig('FieldBox')
  const containerWidth = 350
  const strokeColor = useColorModeValue('black', 'white')
  const backgroundColor = useColorModeValue(
    'background.light',
    'background.dark'
  )
  const styles = {
    border: '0.0625rem solid #9c9c9c',
    borderRadius: '0.25rem',
  }

  const canvasRef = useRef()

  const [sketchPoints, setSketchPoints] = useState([] as Vector2[])
  const [isMouseDown, setIsMouseDown] = useState(false)

  const drawResolution = 4

  //TODO mouse events shouldn't be any types.
  const onMouseMove = (event: any) => {
    if (isMouseDown) {
      const bounds = event.currentTarget.getBoundingClientRect()
      const clientY =
        event._reactName == 'onTouchMove'
          ? event.touches[0].clientY
          : event.clientY
      const clientX =
        event._reactName == 'onTouchMove'
          ? event.touches[0].clientX
          : event.clientX

      const y = clientY - bounds.top
      const x = clientX - bounds.left

      setSketchPoints((prevPoints) => {
        const distanceFromLastPoint =
          prevPoints.length > 1
            ? Math.hypot(
                x - prevPoints[prevPoints.length - 1].x,
                y - prevPoints[prevPoints.length - 1].y
              )
            : drawResolution + 1

        if (distanceFromLastPoint > drawResolution)
          return [...prevPoints, new Vector2(x, y)]
        else return [...prevPoints]
      })
    }
  }

  //TODO remove any type
  const onMouseDown = (event: any) => {
    geometryState.putCurrentStateInPreviousStates()
    websiteState.setModelBeingUpdated(true)
    setIsMouseDown(true)
    setSketchPoints([])
    updateSpline(undefined)
  }

  //TODO remove any type
  const onMouseUp = (event: any) => {
    websiteState.setModelBeingUpdated(false)
    setIsMouseDown(false)
    const filteredPoints = sketchPoints.filter((point, index) => {
      return index % 8 == 0
    })
    const filteredKnots = filteredPoints.map((point) => {
      const normalizedPoint = new Vector2(
        point.x / editorSize!.width,
        point.y / editorSize!.width
      )

      return new IneKnot(normalizedPoint, 0, false, 1, true, false)
    })

    if (filteredKnots.length > 3)
      setSpline(new IneSpline(filteredKnots, socketType))
  }

  function getPolylineString() {
    let points = sketchPoints

    if (points.length != 0) {
      let polylineString = points.reduce(
        (prevValue, currentValue) =>
          prevValue + currentValue.x + ' ' + currentValue.y + ', ',
        ''
      )

      return polylineString.slice(0, -2)
    }
  }

  return (
    <Tooltip label={'Draw with your pointer'} placement={'top'}>
      {
        //TODO make sure getOverhangPolylineStrings() is only executed once during a render becuase it is a computationally intensive function.
      }
      <AspectRatio
        ratio={printBedWidthToHeightRatio}
        width={'80%'}
        maxWidth={containerWidth + 'px'}
      >
        <Flex
          position={'relative'}
          __css={containerStyle}
          padding={'13px'}
          transform={flipped ? 'scaleY(-1)' : 'unset'}
        >
          <Flex
            ref={editorRef}
            position={'relative'}
            width={'100%'}
            height={'100%'}
            onMouseDown={onMouseDown}
            onTouchStart={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchEnd={onMouseUp}
            onMouseMove={onMouseMove}
            onTouchMove={onMouseMove}
          >
            {editorRef.current && (
              <svg height='100%' width='100%'>
                <polyline
                  style={{ stroke: strokeColor, fill: 'none' }}
                  points={
                    sketchPoints.length > 0 && !spline
                      ? getPolylineString()
                      : ''
                  }
                />
                <polyline
                  style={{ stroke: strokeColor, fill: 'none' }}
                  points={spline ? spline.getPolylineString(editorSize!) : ''}
                />
              </svg>
            )}
          </Flex>
        </Flex>
      </AspectRatio>
    </Tooltip>
  )
}
