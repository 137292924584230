import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useStyleConfig,
  useToast,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Heading,
  Textarea,
} from '@chakra-ui/react'

import { merge, assocPath, get as get_in } from 'lodash/fp'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { GoChevronDown } from 'react-icons/go'
import { useState } from 'react'
import { MeshMaker } from 'lib/mesh-maker'
import { Input } from '@chakra-ui/react'
import { settings } from './printer-settings'
import { nil } from 'lib/utils'
import { on } from 'events'

//const BACKEND_HOST = "https://backend.lyla-design.com"
export const BACKEND_HOST = process.env.NEXT_PUBLIC_BACKEND_HOST || "http://localhost:8080"
const LOGIN_URL = BACKEND_HOST + "/api/v1/auth/login"
const CHECK_SESSION_URL = BACKEND_HOST + "/api/v1/auth/check-session"
const LOGOUT_URL = BACKEND_HOST + "/api/v1/auth/logout"
const SLICE_URL = BACKEND_HOST + "/api/v1/slicer/slice"
const PRINTER_URL = BACKEND_HOST + "/api/v1/printer"

var csrf_token = null

export function read_from_storage() {
  let token = localStorage.getItem("backend-session-id")
  return nil(token) ? "" : token
}

function write_to_storage(session_id) {
  if (nil(session_id)) {
    localStorage.removeItem("backend-session-id")
  } else {
    localStorage.setItem("backend-session-id", session_id)
  }
}

// (js/localStorage.getItem "session-id")
// (js/localStorage.setItem "session-id" (pr-str new-value))



function dispatch(status, data, on_success, on_error) {
  if (status < 400) {
    on_success(data)
  } else {
    on_error(data, status)
  }
}

function default_success(data) {
  console.log(data)
}

function default_error(data, status) {
  console.log(data, status)
}

//
function ensure_fn(prefered, backup) {
  return nil(prefered) ? backup : prefered
}

function request(url, method, body, token, on_success, on_error) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',

  }
  if (!nil(token)) {
    headers["Authorization"] = token
  }
  let success_fn = ensure_fn(on_success, default_success)
  let error_fn = ensure_fn(on_error, default_error)
  var payload = {method: method,
    headers: headers}
  if (method === "post") {
    if (! nil(csrf_token)) {
      payload.headers["x-csrf-token"] = csrf_token
    }
    payload.body = body
  }
  payload.credentials = 'include'
  fetch(url, payload).then(response => response.json().then(data => dispatch(response.status, data, success_fn, error_fn)))
}

function post(url, params, token, on_success, on_error) {
  request(url, "post", JSON.stringify(params), token, on_success, on_error)
}

function get(url, params, token, on_success, on_error) {
  //todo add path params into url
  request(url, "get", "", token, on_success, on_error)
}

function save_csrf_token(data){
  let token = data["csrf-token"]
  if(! nil(token)){
    csrf_token = token
  }
}

export function login(email, password, on_success, on_error) {
  let success_fn = (data) => {
    save_csrf_token(data)
    on_success({status: "logged_in", user: data.user}, data)
  }
  let error_fn = (data, status) => {
    save_csrf_token(data)
    on_error({status: "logged_out"}, data, status)
  }
  post(LOGIN_URL, { email: email, password: password }, null, success_fn, error_fn)
  return {status: "logging_in"}
}

export function check_session(on_success, on_error) {
  console.log("checking session")
  let success_fn = (data) => {
    save_csrf_token(data)
    console.log("has valid session")
    on_success({status: "logged_in", user: data.user}, data)
  }
  let error_fn = (data, status) => {
    save_csrf_token(data)
    console.log("has no valid session")
    on_error({status: "logged_out"}, data, status)
  }
  post(CHECK_SESSION_URL, {}, null, success_fn, error_fn)
  return {status: "checking_session"}
}

export function logout(on_success, on_error) {
  let success_fn = (data) => {
    save_csrf_token(data)
    on_success({status: "logged_out"}, data)
  }
  let error_fn = (data, status) => {
    save_csrf_token(data)
    on_error({status: "logged_out"}, data, status)
  }
  post(LOGOUT_URL, { authorization: read_from_storage() }, null, success_fn, error_fn)
  return {status: "logging_out"}
}

export function slice(data, on_success, on_error) {
  post(SLICE_URL, data, null, on_success, on_error)
}

export function fetch_printer_list(params, on_success, on_error) {
  get(PRINTER_URL, params, null, on_success, on_error)
}
