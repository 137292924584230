import { Flex } from '@chakra-ui/react'
import { signIn, signOut, useSession } from 'next-auth/react'
import { useEffect } from 'react'

export default function UserInfo(props: any) {
  const { data: session } = useSession()

  useEffect(() => {
    console.log(session)
  }, [session])

  return (
    <>
      {!session?.user && (
        <Flex cursor={'pointer'} onClick={() => signIn()}>
          LOGIN
        </Flex>
      )}
      {session?.user && (
        <Flex direction={'row'}>
          <Flex>{session.user.name} / </Flex>
          <Flex cursor={'pointer'} onClick={() => signOut()}>
            SIGN OUT
          </Flex>
        </Flex>
      )}
    </>
  )
}
