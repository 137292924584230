import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Flex,
  FormLabel,
  Input,
  Button,
  Heading,
  Card, CardHeader, CardBody, CardFooter,
} from '@chakra-ui/react'
import { createElement, useState, useEffect } from 'react'
import Head from 'next/head'
import ActionButtonDrawer from 'components/action-button-drawer'
import AddButton from 'components/add-button'
import PortraitCollapseButton from 'components/buttons/portrait-collapse-button'
import ColorPicker from 'components/color-picker'
import DDWOption from 'components/ddw-database-options'
import LampPicker from '../components/lamp-model-picker'
import {BackendSlicer} from 'components/slicer'
import LampViewer from 'components/lamp-viewer'
import NewShapeSelector from 'components/new-shape-selector'
import SplineEditor from 'components/spline-editor'
import ConfigPanel from 'components/config-panel'
import {
  constants,
  modelTypeFlags,
  modelTypes,
} from 'lib/constants'
import { pickTextColorBasedOnBgColor } from 'lib/utils'
import MenuButton from 'components/slide-menu'
import IneEditor from 'components/ine-editor'
import SurfaceStructurePicker from 'components/surface-structure-picker'
import { generateIndexPageHooks } from 'pages/index-functions'
import SketchEditor from 'components/sketch-editor'
import SplineScaler from 'components/spline-scaler'
import SphereScaler from 'components/sphere-scaler'
import LoadingNotifier from 'components/loading-notifier'
import TaperScaler from 'components/taper-scaler'
import { login, logout, check_session, BACKEND_HOST} from 'components/backend'


function Login(props) {
  let after_login = props.after_login
  let login_props = props.login_props
  let websiteState = props.websiteState
  let geometryState = props.geometryState
  const [session_state, set_session_state] = useState({status: "checking_session"})
  const [pw, set_pw] = useState('')
  const [email, set_email] = useState('')
  useEffect(() => {
    set_session_state(check_session((state, data) => { set_session_state(state) }, (state, data, status) => { set_session_state(state) }))
    return () => {};
  }, []);
  if (session_state.status === "logged_in") {
    return createElement(after_login, {user: session_state.user, props: login_props, logout: () => set_session_state(logout((state, data) => { set_session_state(state) }, (state, data, status) => { set_session_state(state) })) })
  }
  var disabled = true
  if ((pw.length >= 2) && (email.length >= 2)) {
    disabled = false
  }
  let host = ""
  try { host = window.location.href } catch (err) {

  }
  let url = BACKEND_HOST + "/api/v1/oauth2/google/launch?redirect=" + encodeURI(host)
  if (session_state.status === "logged_out") {
    return (
      <Card
      color={pickTextColorBasedOnBgColor(geometryState.color)}
      bgColor={'#' + geometryState.color}
      justifyContent={'center'}
      width={{
        base: '70vw',
        md: websiteState.sidebarIsOpen ? '20vw' : '0px',
      }}
      minWidth={{
        base: '200px',
        md: websiteState.sidebarIsOpen ? '400px' : '0px',
      }}
      minHeight={'unset'}

      // transition="all 1s ease"
      overflow={'hidden'}
      rounded={'lg'}
      boxShadow={'lg'}

    >
          <CardHeader>
            <Heading size='md'>Welcome to Lyla Editor</Heading>
          </CardHeader>
        <CardBody>
        <FormLabel>Email</FormLabel>
        <Flex>

          <Input
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => set_email(e.target.value)}
          />
        </Flex>
        <FormLabel>Password</FormLabel>
        <Flex>

          <Input
            type='password'
            placeholder='Enter password'
            value={pw}
            onChange={(e) => set_pw(e.target.value)}
          />
        </Flex>
        <Button

          onClick={() => set_session_state(login(email, pw, (state, data) => { set_session_state(state);  set_pw(""); }, (state, data, status) => { set_session_state(state);  set_pw(""); }))}
          justifyContent={'center'}
          backgroundColor={props.color}
          padding={'0.5em'}
          borderRadius={'0.5em'}
          borderWidth={'2px'}
          borderColor={props.borderColor}
          cursor={'pointer'}
          isDisabled={disabled}
          m='1'
        >
          Login
        </Button>
        <div> - or -</div>
        <Flex>
        <Button

            justifyContent={'center'}
            backgroundColor={props.color}
            padding={'0.5em'}
            borderRadius={'0.5em'}
            borderWidth={'2px'}
            borderColor={props.borderColor}
            cursor={'pointer'}
            m='1'>
            <a  href={url}>
            Connect with Google
          </a>
        </Button>
        </Flex>


          </CardBody>
      </Card>
    )
  }
  if (session_state.status === "logging_out") {
    return <div> logging out</div>
  }
  if (session_state.status === "logging_in") {
    return <div> logging in</div>
  }
  if (session_state.status === "checking_session") {
    return <div> checking session </div>
  } else {
    return (
      <div>Error this should not be reachable!</div>
    )
  }
}

function LoggedIn(props){
  let logout = props.logout
  let geometryState = props.props.geometryState
  let websiteState = props.props.websiteState
  let router = props.props.router
  let user = props.user
  let allow_slicing = user["account/allow-slicer?"]
  return <Flex
        alignSelf={'center'}
        alignItems={'flex-start'}
        height={{ base: 'unset', md: '100vh' }}
        paddingTop={{ base: '0', md: '15vh' }}
        direction={{ base: 'column', md: 'row' }}
        paddingRight={{
          base: '0em',
          md: websiteState.sidebarIsOpen ? '8em' : '0em',
        }}
        transition='all 1s ease'
      >
        <ActionButtonDrawer
          etsy={false}
          router={router}
          geometryState={geometryState}
          websiteState={websiteState}
        />

        <Accordion
          color={pickTextColorBasedOnBgColor(geometryState.color)}
          bgColor={'#' + geometryState.color}
          justifyContent={'center'}
          width={{
            base: '70vw',
            md: websiteState.sidebarIsOpen ? '20vw' : '0px',
          }}
          minWidth={{
            base: '200px',
            md: websiteState.sidebarIsOpen ? '400px' : '0px',
          }}
          minHeight={'unset'}
          maxHeight={{
            base: websiteState.sidebarIsOpen ? '100vh' : '4em',
            md: 'unset',
          }}
          // transition="all 1s ease"
          overflow={'hidden'}
          rounded={'lg'}
          boxShadow={'lg'}
        >
          {!websiteState.isLandscape && (
            <Center marginTop={'1em'}>
              <PortraitCollapseButton
                setSidebarIsOpen={websiteState.setSidebarIsOpen}
                sidebarOpen={websiteState.sidebarIsOpen}
              />
            </Center>
          )}

          <Center>
            <LampPicker
              geometryState={geometryState}
              websiteState={websiteState}
            />
          </Center>

          <Center>
            <ColorPicker
              color={geometryState.color}
              setColor={geometryState.setColor}
            />
          </Center>

          <AccordionItem
            borderColor={pickTextColorBasedOnBgColor(
              geometryState.color,
              constants.colors.semiTransparentWhite,
              constants.colors.semiTransparentBlack
            )}
          >
            <AccordionButton>
              <Flex flex='1' textAlign='left' justifyContent={'center'}>
                <Flex width={{ base: '85%', md: '87%' }} fontSize={'1em'}>
                  SHAPE
                </Flex>
              </Flex>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel
              display={'flex'}
              flexDirection={'column'}
              paddingX={0}
              width='100%'
              alignItems={'center'}
              justifyContent={'center'}
              color='black'
            >
              {geometryState.modelType.flags.includes(
                modelTypeFlags.isSplineBased
              ) &&
                websiteState.hasMounted && (
                  <SplineEditor
                    geometryState={geometryState}
                    websiteState={websiteState}
                  />
                )}

              {geometryState.modelType.flags.includes(
                modelTypeFlags.isIneBased
              ) &&
                websiteState.hasMounted && (
                  <IneEditor
                    geometryState={geometryState}
                    websiteState={websiteState}
                  />
                )}

              {geometryState.modelType.flags.includes(
                modelTypeFlags.isSketchBased
              ) &&
                websiteState.hasMounted && (
                  <SketchEditor
                    geometryState={geometryState}
                    websiteState={websiteState}
                  />
                )}

              {geometryState.modelType.flags.includes(
                modelTypeFlags.shouldHaveSplineScaler
              ) && (
                <SplineScaler
                  geometryState={geometryState}
                  websiteState={websiteState}
                  minHeight={0.2}
                />
              )}

              {geometryState.spline.modelType.id == modelTypes.bubble.id && (
                <TaperScaler
                  geometryState={geometryState}
                  websiteState={websiteState}
                />
              )}

              {geometryState.spline &&
                websiteState.sidebarIsOpen &&
                geometryState.modelType.id == modelTypes.bubble.id &&
                websiteState.selectedSphere != -1 && (
                  <SphereScaler
                    geometryState={geometryState}
                    websiteState={websiteState}
                  />
                )}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem
            borderColor={pickTextColorBasedOnBgColor(
              geometryState.color,
              constants.colors.semiTransparentWhite,
              constants.colors.semiTransparentBlack
            )}
          >
            <AccordionButton>
              <Flex flex='1' textAlign='left' justifyContent={'center'}>
                <Flex width={{ base: '85%', md: '87%' }}>SURFACE</Flex>
              </Flex>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel
              display={'flex'}
              paddingX={0}
              width='100%'
              color='black'
              justifyContent={'center'}
            >
              <SurfaceStructurePicker
                geometryState={geometryState}
                websiteState={websiteState}
              />
            </AccordionPanel>
          </AccordionItem>

          {geometryState.modelType.flags.includes(
            modelTypeFlags.shouldShowAdvancedEditor
          ) && (
            <AccordionItem
              borderColor={pickTextColorBasedOnBgColor(
                geometryState.color,
                constants.colors.semiTransparentWhite,
                constants.colors.semiTransparentBlack
              )}
            >
              <AccordionButton>
                <Flex flex='1' textAlign='left' justifyContent={'center'}>
                  <Flex width={{ base: '85%', md: '87%' }}>
                    ADVANCED EDITOR
                  </Flex>
                </Flex>
                <AccordionIcon />
              </AccordionButton>

              <Center>
                <AccordionPanel
                  pb={4}
                  overflowY={'scroll'}
                  sx={{
                    '::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                  color='black'
                  maxHeight={{ base: '30vh', md: '50vh' }}
                >
                  {websiteState.hasMounted
                    ? geometryState.profiles.intermediateShapes.map(
                        (value, index) => {
                          return (
                            <NewShapeSelector
                              geometryState={geometryState}
                              websiteState={websiteState}
                              borderColor={pickTextColorBasedOnBgColor(
                                geometryState.color,
                                constants.colors.semiTransparentWhite,
                                constants.colors.semiTransparentBlack
                              )}
                              key={websiteState.randomSalt + ' ' + index}
                              index={index + 1}
                            />
                          )
                        }
                      )
                    : false}

                  {geometryState.profiles.intermediateShapes.length == 0 && (
                    <AddButton
                      color={geometryState.color}
                      editorWidth={constants.editorWidth / 2}
                      profileHook={[
                        geometryState.profiles,
                        geometryState.setProfiles,
                      ]}
                    ></AddButton>
                  )}

                  <NewShapeSelector
                    geometryState={geometryState}
                    websiteState={websiteState}
                    key={websiteState.randomSalt + '-1'}
                    borderColor={pickTextColorBasedOnBgColor(
                      geometryState.color,
                      constants.colors.semiTransparentWhite,
                      constants.colors.semiTransparentBlack
                    )}
                    index={-1}
                  />
                </AccordionPanel>
              </Center>
            </AccordionItem>
          )}

            <AccordionItem
              borderColor={pickTextColorBasedOnBgColor(
                geometryState.color,
                constants.colors.semiTransparentWhite,
                constants.colors.semiTransparentBlack
              )}
            >
              <AccordionButton>
                <Flex flex='1' textAlign='left' justifyContent={'center'}>
                  <Flex width={{ base: '85%', md: '87%' }}>CONFIG</Flex>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel
                display={'flex'}
                flexDirection={'column'}
                paddingX={0}
                width='100%'
                alignItems={'center'}
                justifyContent={'center'}
              >
                <ConfigPanel
                  websiteState={websiteState}
                  geometryState={geometryState}
                />
              </AccordionPanel>
            </AccordionItem>
          <AccordionItem
            borderColor={pickTextColorBasedOnBgColor(
              geometryState.color,
              constants.colors.semiTransparentWhite,
              constants.colors.semiTransparentBlack
            )}
          >
            <AccordionButton>
              <Flex flex='1' textAlign='left' justifyContent={'center'}>
                <Flex width={{ base: '85%', md: '87%' }}>
                  GCODE Generator
                </Flex>
              </Flex>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
              <Center>
                {allow_slicing &&
                <BackendSlicer
                  borderColor={pickTextColorBasedOnBgColor(
                    geometryState.color,
                    constants.colors.semiTransparentWhite,
                    constants.colors.semiTransparentBlack
                  )}
                  geometryState={geometryState}
                />}
                {!allow_slicing &&
                <div>
                  <div>Get in contact with us to get access to GCode generation.
                  </div>
                  <Button>
                  <a href='mailto:hi@lyla-design.com'>Contact Support</a>
                  </Button>
                </div>
               }
              </Center>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
          <Button
          onClick={logout}
          justifyContent={'center'}
          backgroundColor={props.color}
          padding={'0.5em'}
          borderRadius={'0.5em'}
          borderWidth={'2px'}
          borderColor={props.borderColor}
          cursor={'pointer'}
          m='1'
        >
          Logout
        </Button>
          </AccordionItem>
        </Accordion>
      </Flex>
}



export default function Home() {
  const {
    geometryState,
    websiteState,
    router,
    lampSetter,
    meshSetter,
    canvasRef,
    canvasSize,
  } = generateIndexPageHooks()

  return (
    <div>
      <Head>
          <title>Lyla Designer</title>
          <meta
            name='description'
            content='Make your own 3D printable designs'
          />
          <link rel='icon' href='/favicon.ico' />
      </Head>
      <Flex
        p={'15px'}
        height={websiteState.hasMounted ? window.innerHeight : '100vh'}
        fontSize={{ base: '0.5em', md: '1em' }}
        direction={{ base: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <MenuButton></MenuButton>

        {websiteState.showLoader && canvasRef.current && (
          <LoadingNotifier canvasRef={canvasRef} />
        )}
        <LampViewer
          canvasRef={canvasRef}
          socketColor={'0x000000'}
          geometryState={geometryState}
          websiteState={websiteState}
        />
         <Login
          after_login={LoggedIn}
          login_props={{geometryState: geometryState, websiteState: websiteState, router: router}}
          geometryState={geometryState}
          websiteState={websiteState}
          router={router}
          ></Login>
      </Flex>
    </div>
  )
}
