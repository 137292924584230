import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useStyleConfig,
  useToast,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Heading,
  Textarea,
} from '@chakra-ui/react'

import { merge, assocPath, get as get_in } from 'lodash/fp'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { GoChevronDown } from 'react-icons/go'
import { createElement, useState, useEffect } from 'react'
import { MeshMaker } from 'lib/mesh-maker'
import { Input } from '@chakra-ui/react'
import { settings } from './printer-settings'
import { nil } from 'lib/utils'
import { login, logout, check_session, slice, read_from_storage, fetch_printer_list, BACKEND_HOST} from 'components/backend'
import { GeometryState } from 'lib/state'


export function OptionInput(props) {
  return (
    <NumberInput size='md' maxW={24} maxH={10} m='0.5' {...props}>
      <NumberInputField bg={'white'} color={'black'} />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}


function generate_settings(printer) {
  const defaults = settings.default
  const printer_settings = settings[printer]
  let slicer_settings = merge(defaults, printer_settings)
  if (nil(slicer_settings.base.max_flowrate)) {
    slicer_settings = assocPath(
      ['base', 'max_flowrate'],
      slicer_settings.wall.max_flowrate,
      slicer_settings
    )
  }
  if (nil(slicer_settings.base.max_speed)) {
    slicer_settings = assocPath(
      ['base', 'max_speed'],
      slicer_settings.wall.max_speed,
      slicer_settings
    )
  }
  if (nil(slicer_settings.base.extrusion_multiplier)) {
    slicer_settings = assocPath(
      ['base', 'extrusion_multiplier'],
      slicer_settings.wall.extrusion_multiplier,
      slicer_settings
    )
  }
  return { printer: printer, slicer_settings: slicer_settings }
}

export function GCODEOptionsHidden(props) {
  const [printer_list, set_printer_list] = useState({})

  const pickerStyles = useStyleConfig('FieldBox', { variant: 'pickerInput' })
  const [is_busy, set_is_busy] = useState(
    false
  )

  const [settings_state, set_settings_state] = useState(
    {}
  )
  const slicer_settings = settings_state.slicer_settings
  const assoc_in = (
    path,
    fn = (x) => {
      return x
    }
  ) => {
    return (value) => {
      let new_state = assocPath(path, fn(value), settings_state)
      set_settings_state(new_state)
    }
  }
  const update_in = (path, fn) => {
    return (_) => {
      let old_val = get_in(path, settings_state)
      let new_val = fn(old_val)
      let new_state = assocPath(path, new_val, settings_state)
      set_settings_state(new_state)
    }
  }
  const toast = useToast()

  const toggle = (val) => {
    return !val
  }

  let on_success = (data) => {
    let k = Object.keys(data.data)[0]
    let entry = data.data[k]
    set_printer_list(data.data)
    if (nil(entry)){
      set_settings_state(generate_settings("default"))
    }
    else {
      set_settings_state({ printer: entry["name"], slicer_settings: entry })

    }
  }
  let on_error = (data, status) => {
    set_is_busy(false)
    toast({
      title: 'Could not load printers. please reload the page',
      description: data.error,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }
  if (nil( settings_state.printer)) {
    fetch_printer_list({ }, on_success, on_error)
    return <div>"Loading printers</div>
  }

  const exportGCODE = () => {
    let max_distance = (slicer_settings.performance.max_distance === "") ? slicer_settings.general.nozzle : slicer_settings.performance.max_distance;
    let min_distance = (slicer_settings.performance.min_distance === "") ? slicer_settings.general.nozzle * 0.5 : slicer_settings.performance.min_distance;

    const opts = {
      printer: {
        name: settings_state.printer,
        firmware: slicer_settings.firmware,
      },

      design_space: {
        x: slicer_settings.general.design_space.x,
        y: slicer_settings.general.design_space.y,
        z: slicer_settings.general.design_space.z,
      },
      radial_coord_sys: slicer_settings.general.radial,
      path: {
        max_curvature: slicer_settings.checking.max_curvature, // value is cos(alhpa) =>  0.707, // 45° angle
        max_ddistance: max_distance, // the difference in distance between 3 adjacent points must in range of x %
        min_ddistance: min_distance, // the difference in distance between 3 adjacent points must in range of x %
      },
      min_layer_height: slicer_settings.wall.min_height,
      max_layer_height: slicer_settings.wall.max_height,
      min_overlap: slicer_settings.wall.adaptive_layerheights.min_overlap,
      adaptive_heights: slicer_settings.wall.adaptive_layerheights.on,
      curve_step: 1.0 / slicer_settings.performance.guidepoints,
      oversampling: slicer_settings.performance.oversampling,
      use_steps: [],
      nozzle_size: slicer_settings.general.nozzle,
      layer_width: slicer_settings.general.nozzle,
      z_offset: slicer_settings.general.z_offset,
      filament_diameter: slicer_settings.general.filament_diameter,
      max_flow_rate: slicer_settings.wall.max_flowrate,
      max_print_speed: slicer_settings.wall.max_speed,
      extrusion_multiplier: slicer_settings.wall.extrusion_multiplier,
      ramp_up: slicer_settings.base.ramp_up,
      layer_time: slicer_settings.wall.min_layer_time,
      is_adaptive_wallthickness: slicer_settings.wall.adaptive_wallthickness.on,
      surface_modifier: props.geometryState.surfaceModifier,
      brim: {
        layer_height: slicer_settings.base.fill.height,
        max_print_speed: slicer_settings.base.max_speed,
        max_flow_rate: slicer_settings.base.max_flowrate,
        extrusion_multiplier: slicer_settings.base.extrusion_multiplier,
        rounds: slicer_settings.base.brim.rounds,
        cleanup_rounds: slicer_settings.base.skirt.rounds,
        type: null,
        fill_type: slicer_settings.base.fill.fill_method,
        socket: slicer_settings.base.fill.socket,
        layers: slicer_settings.base.fill.layers,
      },
    }
    let on_success = (data) => {
      set_is_busy(false)
      const result = data.data
      const link = document.createElement('a')
      link.style.display = 'none'
      document.body.appendChild(link)
      link.href = URL.createObjectURL(
        new Blob([result], { type: 'text/plain' })
      )
      link.download = 'lamp_gcode.gcode'
      link.click()
    }
    let on_error = (data, status) => {
      set_is_busy(false)
      toast({
        title: 'Could not generate gcode',
        description: data.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    let geometry = GeometryState.serializeExportedState(props.geometryState)
    slice({ geometry: geometry, options: opts, type: "vase" }, on_success, on_error)
    set_is_busy(true)
  }
  let button_message = is_busy ? "Slicing Design" : "Generate GCode"
  return (
    <Flex marginY={'0.5em'} direction={'column'} width={'100%'}>
      <Tabs>
        <TabList>
          <Tab>Basic</Tab>
          <Tab>More</Tab>
          <Tab>Advanced</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex>
              Printer

              <Menu>
                <MenuButton
                  color='black'
                  as={Button}
                  rightIcon={<GoChevronDown />}
                >
                  {settings_state.printer}
                </MenuButton>
                <MenuList color='black'
                  maxHeight="150px"
                  overflowY={"scroll"}>
                  {Object.keys(printer_list).map((k) => {
                    let data = printer_list[k]
                    return (
                      <MenuItem
                        key={k}
                        onClick={() => {
                          set_settings_state({ printer: data["name"], slicer_settings: data })
                        }}
                      >
                        {data["name"]}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            </Flex>
            <Flex>
              Material
              <Menu>
                <MenuButton
                  color='black'
                  as={Button}
                  rightIcon={<GoChevronDown />}
                >
                  FDF
                </MenuButton>
                <MenuList color='black'
                  zIndex="dropdown"
                  maxHeight="100px"
                  overflowY={"scroll"}
                >
                  <MenuItem>FDF</MenuItem>
                  <MenuItem>FDM</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Tabs>
              <TabList>
                <Tab>General</Tab>

                <Tab>Walls</Tab>
                <Tab>Base Layer</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Heading as='h5' size='sm'>
                    Design Space
                  </Heading>
                  <Flex>
                    <FormLabel>X</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={1}
                      placeholder={'Enter x'}
                      value={slicer_settings.general.design_space.x}
                      onChange={assoc_in(
                        ['slicer_settings', 'general', 'design_space', 'x'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel>Y</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={1}
                      placeholder={'Enter y'}
                      value={slicer_settings.general.design_space.y}
                      onChange={assoc_in(
                        ['slicer_settings', 'general', 'design_space', 'y'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel>Z</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={1}
                      placeholder={'Enter Z'}
                      value={slicer_settings.general.design_space.z}
                      onChange={assoc_in(
                        ['slicer_settings', 'general', 'design_space', 'z'],
                        parseFloat
                      )}
                    />
                  </Flex>

                  <Flex>
                    <FormControl display='flex' alignItems='center'>
                      <FormLabel htmlFor='radial' mb='0'>
                        Radial Printer?
                      </FormLabel>
                      <input
                        id='radial'
                        type='checkbox'
                        checked={slicer_settings.general.radial}
                        onChange={update_in(
                          ['slicer_settings', 'general', 'radial'],
                          toggle
                        )}
                      />
                    </FormControl>
                  </Flex>
                  <Flex>
                    <FormLabel> Filament Diameter</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={4}
                      placeholder={'filament diameter'}
                      value={slicer_settings.general.filament_diameter}
                      onChange={assoc_in(
                        ['slicer_settings', 'general', 'filament_diameter'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel>Layer width</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={2}
                      placeholder={'nozzle size'}
                      value={slicer_settings.general.nozzle}
                      onChange={assoc_in(
                        ['slicer_settings', 'general', 'nozzle'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel>Z-Offset</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={2}
                      placeholder={'z offset'}
                      value={slicer_settings.general.z_offset}
                      onChange={assoc_in(
                        ['slicer_settings', 'general', 'z_offset'],
                        parseFloat
                      )}
                    />
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex>
                    <FormLabel>Min Layer Time [s]</FormLabel>
                    <OptionInput
                      min={1}
                      step={1}
                      precision={1}
                      placeholder={'layer time in seconds'}
                      value={slicer_settings.wall.min_layer_time}
                      onChange={assoc_in(
                        ['slicer_settings', 'wall', 'min_layer_time'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel>Max FlowRate</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={2}
                      placeholder={'max flow rate'}
                      value={slicer_settings.wall.max_flowrate}
                      onChange={assoc_in(
                        ['slicer_settings', 'wall', 'max_flowrate'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel>Max Print Speed</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={2}
                      placeholder={'print speed'}
                      value={slicer_settings.wall.max_speed}
                      onChange={assoc_in(
                        ['slicer_settings', 'wall', 'max_speed'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel>Extrusion Multiplier</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={2}
                      placeholder={'extrusion rate'}
                      value={slicer_settings.wall.extrusion_multiplier}
                      onChange={assoc_in(
                        ['slicer_settings', 'wall', 'extrusion_multiplier'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Heading as='h5' size='sm'>
                    Overhang Optimazation
                  </Heading>
                  <Flex>
                    <FormLabel> Min Height</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={2}
                      placeholder={'min height'}
                      value={slicer_settings.wall.min_height}
                      onChange={assoc_in(
                        ['slicer_settings', 'wall', 'min_height'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel> Max Height</FormLabel>
                    <OptionInput
                      min={0}
                      step={0.1}
                      precision={2}
                      placeholder={'height'}
                      value={slicer_settings.wall.max_height}
                      onChange={assoc_in(
                        ['slicer_settings', 'wall', 'max_height'],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel> Min Overlap</FormLabel>
                    <OptionInput
                      min={0.0}
                      max={1.0}
                      step={0.01}
                      precision={2}
                      placeholder={'overlap in %'}
                      value={
                        slicer_settings.wall.adaptive_layerheights.min_overlap
                      }
                      onChange={assoc_in(
                        [
                          'slicer_settings',
                          'wall',
                          'adaptive_layerheights',
                          'min_overlap',
                        ],
                        parseFloat
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel htmlFor='adaptive-heights' mb='0'>
                      Adaptive Heights?
                    </FormLabel>
                    <input
                      id='adaptive-heights'
                      type='checkbox'
                      checked={slicer_settings.wall.adaptive_layerheights.on}
                      onChange={update_in(
                        [
                          'slicer_settings',
                          'wall',
                          'adaptive_layerheights',
                          'on',
                        ],
                        toggle
                      )}
                    />
                  </Flex>
                  <Flex>
                    <FormLabel htmlFor='adaptive-wallthickness' mb='0'>
                      Adaptive Wallthickness?
                    </FormLabel>
                    <input
                      id='adaptive-wallthickness'
                      type='checkbox'
                      checked={slicer_settings.wall.adaptive_wallthickness.on}
                      onChange={update_in(
                        [
                          'slicer_settings',
                          'wall',
                          'adaptive_wallthickness',
                          'on',
                        ],
                        toggle
                      )}
                    />
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <div>
                    <Flex>
                      <FormLabel>max flow rate</FormLabel>
                      <OptionInput
                        min={0}
                        step={1}
                        precision={0}
                        placeholder={'max flow rate'}
                        value={slicer_settings.base.max_flowrate}
                        onChange={assoc_in(
                          ['slicer_settings', 'base', 'max_flowrate'],
                          parseFloat
                        )}
                      />
                    </Flex>
                    <Flex>
                      <FormLabel>max print speed</FormLabel>
                      <OptionInput
                        min={0}
                        step={1}
                        precision={0}
                        placeholder={'max print speed'}
                        value={slicer_settings.base.max_speed}
                        onChange={assoc_in(
                          ['slicer_settings', 'base', 'max_speed'],
                          parseFloat
                        )}
                      />
                    </Flex>
                    <Flex>
                      <FormLabel>extrusion multiplier</FormLabel>
                      <OptionInput
                        min={0}
                        step={0.1}
                        precision={2}
                        placeholder={'extrusion_multiplier'}
                        value={slicer_settings.base.extrusion_multiplier}
                        onChange={assoc_in(
                          ['slicer_settings', 'base', 'extrusion_multiplier'],
                          parseFloat
                        )}
                      />
                    </Flex>
                    <Flex>
                      <FormLabel>Ramp Up Number of Points</FormLabel>
                      <OptionInput
                        min={1}
                        step={1}
                        precision={1}
                        placeholder={'time to accelerate in number of points'}
                        value={slicer_settings.base.ramp_up}
                        onChange={assoc_in(
                          ['slicer_settings', 'base', 'ramp_up'],
                          parseFloat
                        )}
                      />
                    </Flex>
                    <Heading as='h5' size='sm'>
                      Socket
                    </Heading>
                    <Flex>
                      <Menu>
                        <MenuButton
                          color='black'
                          as={Button}
                          rightIcon={<GoChevronDown />}
                        >
                          {slicer_settings.base.fill.socket}
                        </MenuButton>
                        <MenuList color='black'>
                          <MenuItem
                            onClick={() =>
                              assoc_in([
                                'slicer_settings',
                                'base',
                                'fill',
                                'socket',
                              ])('native')
                            }
                          >
                            native
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              assoc_in([
                                'slicer_settings',
                                'base',
                                'fill',
                                'socket',
                              ])('full')
                            }
                          >
                            full
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              assoc_in([
                                'slicer_settings',
                                'base',
                                'fill',
                                'socket',
                              ])('none')
                            }
                          >
                            none
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                    <Heading as='h5' size='sm'>
                      Fillmethod
                    </Heading>
                    <Flex>
                      <Menu>
                        <MenuButton
                          color='black'
                          as={Button}
                          rightIcon={<GoChevronDown />}
                        >
                          {slicer_settings.base.fill.fill_method}
                        </MenuButton>
                        <MenuList color='black'>
                          <MenuItem
                            onClick={() =>
                              assoc_in([
                                'slicer_settings',
                                'base',
                                'fill',
                                'fill_method',
                              ])('offset')
                            }
                          >
                            offset
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              assoc_in([
                                'slicer_settings',
                                'base',
                                'fill',
                                'fill_method',
                              ])('scanline')
                            }
                          >
                            scanline
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              assoc_in([
                                'slicer_settings',
                                'base',
                                'fill',
                                'fill_method',
                              ])('scanline90')
                            }
                          >
                            scanline90
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                    <Flex>
                      <FormLabel>Height</FormLabel>
                      <OptionInput
                        min={0}
                        step={0.1}
                        precision={2}
                        placeholder={'height'}
                        value={slicer_settings.base.fill.height}
                        onChange={assoc_in(
                          ['slicer_settings', 'base', 'fill', 'height'],
                          parseFloat
                        )}
                      />
                    </Flex>

                    <Flex>
                      <FormLabel>Layers</FormLabel>
                      <OptionInput
                        min={1}
                        step={1}
                        precision={0}
                        placeholder={'Layers'}
                        value={slicer_settings.base.fill.layers}
                        onChange={assoc_in(
                          ['slicer_settings', 'base', 'fill', 'layers'],
                          parseFloat
                        )}
                      />
                    </Flex>
                  </div>
                  <div>
                    <Heading as='h5' size='sm'>
                      Brim
                    </Heading>
                    <Flex>
                      <FormLabel>Rounds</FormLabel>
                      <OptionInput
                        min={0}
                        step={1}
                        precision={0}
                        placeholder={'brim rounds'}
                        value={slicer_settings.base.brim.rounds}
                        onChange={assoc_in(
                          ['slicer_settings', 'base', 'brim', 'rounds'],
                          parseFloat
                        )}
                      />
                    </Flex>
                  </div>
                  <div>
                    <Heading as='h5' size='sm'>
                      Skirt
                    </Heading>
                    <Flex>
                      <FormLabel>Rounds</FormLabel>
                      <OptionInput
                        min={0}
                        step={1}
                        precision={0}
                        placeholder={'cleanup rounds'}
                        value={slicer_settings.base.skirt.rounds}
                        onChange={assoc_in(
                          ['slicer_settings', 'base', 'skirt', 'rounds'],
                          parseFloat
                        )}
                      />
                    </Flex>
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <Heading as='h5' size='sm'>
              Result Checking
            </Heading>
            <Flex>
              <FormLabel>Max Curvature</FormLabel>
              <OptionInput
                min={-1}
                step={0.1}
                precision={2}
                placeholder={'max curvature'}
                value={slicer_settings.checking.max_curvature}
                onChange={assoc_in(
                  ['slicer_settings', 'checking', 'max_curvature'],
                  parseFloat
                )}
              />
            </Flex>
            <Heading as='h5' size='sm'>
              Performance
            </Heading>
            <Flex>
              <FormLabel> Guidepoints</FormLabel>
              <OptionInput
                min={20}
                step={1}
                placeholder={'guide points'}
                value={slicer_settings.performance.guidepoints}
                onChange={assoc_in(
                  ['slicer_settings', 'performance', 'guidepoints'],
                  parseFloat
                )}
              />
            </Flex>

            <Flex>
              <FormLabel> Over Sampling</FormLabel>
              <OptionInput
                min={2}
                step={1}
                precision={1}
                placeholder={'oversampling'}
                value={slicer_settings.performance.oversampling}
                onChange={assoc_in(
                  ['slicer_settings', 'performance', 'oversampling'],
                  parseFloat
                )}
              />
            </Flex>
            <Flex>
              <FormLabel>Min Distance</FormLabel>
              <OptionInput
                min={0.01}
                step={0.1}
                precision={2}
                placeholder={'min distance'}
                value={slicer_settings.performance.min_distance}
                onChange={assoc_in(
                  ['slicer_settings', 'performance', 'min_distance'],
                  parseFloat
                )}
              />
            </Flex>
            <Flex>
              <FormLabel>Max Distance</FormLabel>
              <OptionInput
                min={0.01}
                step={0.1}
                precision={2}
                placeholder={'max distance'}
                value={slicer_settings.performance.max_distance}
                onChange={assoc_in(
                  ['slicer_settings', 'performance', 'max_distance'],
                  parseFloat
                )}
              />
            </Flex>
            <Flex>
              <FormLabel>Start Code</FormLabel>
            </Flex>
            <Flex>
              <Textarea
                value={slicer_settings.firmware.start_code}
                onChange={assoc_in(
                  ['slicer_settings', 'firmware', 'start_code'],
                  (x) => {
                    x.target.value
                  }
                )}
                placeholder='start code'
                size='sm'
              />
            </Flex>
            <FormLabel>End Code</FormLabel>
            <Flex>
              <Textarea
                value={slicer_settings.firmware.end_code}
                onChange={assoc_in(
                  ['slicer_settings', 'firmware', 'end_code'],
                  (x) => {
                    x.target.value
                  }
                )}
                placeholder='start code'
                size='sm'
              />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex>
        <Button
          onClick={() => exportGCODE()}
          justifyContent={'center'}
          backgroundColor={props.color}
          padding={'0.5em'}
          borderRadius={'0.5em'}
          borderWidth={'2px'}
          borderColor={props.borderColor}
          cursor={'pointer'}
          isDisabled={is_busy}
          m='1'
        >
          {button_message}
        </Button>
      </Flex>
    </Flex>
  )
}


export function BackendSlicer(props){
  return <GCODEOptionsHidden {...props}>
  </GCODEOptionsHidden>
}