import { Flex, useColorModeValue } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaBars, FaArrowCircleLeft } from 'react-icons/fa'
import styles from '../styles/Menu.module.scss'
import Link from 'next/link'
import { useRouter } from 'next/router'
import UserInfo from './userinfo'

function MenuContent(props) {
  let toggleOpen = props.toggleOpen
  return (
    <Flex
      p={'0px'}
      boxShadow={'lg'}
      height={props.open ? 'calc(100vh - 80px)' : '0px'}
      overflow={'hidden'}
      width={props.open ? 'calc(100vw - 40px)' : '0px'}
      backgroundColor={props.backgroundColor}
      color={props.textColor}
      margin='10px'
      direction={'row'}
      alignItems={'start'}
      justifyContent={'flex-start'}
    >
      <ul className={styles.menuList}>
        <li className={styles.spaceBelow}>
          <Link href='/models'>DDW Visitor Designs</Link>
        </li>
        <li>
          <Link href='/about_us'>
            About Us
          </Link>
        </li>
        <li className={styles.spaceBelow}>
          <a
            href='https://www.dropbox.com/sh/rc4oz1gi8whdcv0/AADq0IAsBfQD8eGzXdCp6pLka?dl=0'
            target='_blank'
            rel='noreferrer'
          >
            Media
          </a>
        </li>
        <li>
          <a href='mailto:hi@lyla-design.com'>Contact</a>
        </li>
        <li>
          <a
            href='https://www.instagram.com/lyla_design_eu/'
            target='_blank'
            rel='noreferrer'
          >
            Instagram
          </a>
        </li>
        <li className={styles.spaceBelow}>
          <a
            href='https://www.linkedin.com/company/lyladesign/'
            target='_blank'
            rel='noreferrer'
          >
            Linkedin
          </a>
        </li>
        <li>
          <Link href='/impressum'>Data & Impressum</Link>
        </li>
      </ul>
    </Flex>
  )
}

export default function MenuButton() {
  let router = useRouter()
  let [open, setOpen] = useState(false)
  let [activeMenuItem, setActiveMenuItem] = useState(null)
  let toggleOpen = () => {
    setOpen(!open)
    if (
      open &&
      window.location.pathname != '' &&
      window.location.pathname != '/'
    ) {
      router.push('/')
    }
  }
  const backgroundColor = useColorModeValue('white', '#1A202C')
  const textColor = useColorModeValue('black', 'white')

  return (
    <div
      className={styles.menuButtonContainer}
      style={{ backgroundColor: open ? backgroundColor : 'transparent' }}
    >
      <div
        className={styles.menuButton}
        style={{
          cursor: 'unset',
          backgroundColor: open ? backgroundColor : 'transparent',
          color: textColor,
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100vw - 40px)',
          justifyContent: 'space-between',
        }}
      >
        <Flex alignItems={'center'} onClick={toggleOpen} cursor={'pointer'}>
          {open ? (
            <FaArrowCircleLeft className={styles.menuSandwichIcon} />
          ) : (
            <FaBars className={styles.menuSandwichIcon} />
          )}
          <span className={styles.menuButtonText}>LYLA</span>
        </Flex>
      </div>
      <MenuContent
        open={open}
        backgroundColor={backgroundColor}
        textColor={textColor}
        activeMenuItem={activeMenuItem}
        setActiveMenuItem={setActiveMenuItem}
        toggleOpen={toggleOpen}
      />
    </div>
  )
}
