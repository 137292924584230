import { Flex, useStyleConfig } from '@chakra-ui/react'
import {
  constants,
  editorTypes,
  modelTypeFlags,
  modelTypes,
} from 'lib/constants'
import { SliderContainer } from './new-shape-selector'
import { Knot, Spline } from 'lib/spline-classes'
import { config, smallerXYDimension } from 'lib/configs'
import { GeometryState, WebsiteState } from 'lib/state'

/*
 * These sliders are used for types of geometries where the spline is just a straight line but we need
 * to provide a width and height
 */

interface SplineScalerProps {
  geometryState: GeometryState
  websiteState: WebsiteState
  minHeight?: number
  minWidth?: number
}

export default function SplineScaler({
  geometryState,
  websiteState,
  minHeight,
  minWidth,
}: SplineScalerProps) {
  const pickerStyles = useStyleConfig('FieldBox', { variant: 'pickerInput' })
  const [spline, setSpline] = [geometryState.spline, geometryState.setSpline]

  const printbedSize = spline.modelType.editorType.printerType.dimensions
  const smallerWidthDimension =
    spline.modelType.editorType.getSmallerXYDimension()

  const onWidthChange = (value: number) => {
    if (spline.modelType.id != modelTypes.candle.id) {
      const knots = [...spline.knots]
      knots.forEach(
        (knot) => (knot.position.x = (value * 10) / smallerWidthDimension)
      )
      setSpline(
        new Spline(knots, spline.modelType, spline.overhangAngleDegrees),
        false
      )
    } else {
      const knots = [...spline.knots]
      knots.forEach(
        (knot) => (knot.position.x = (value * 10) / smallerWidthDimension)
      )
      const newSpline = new Spline(
        knots,
        spline.modelType,
        spline.overhangAngleDegrees
      )
      const radius =
        newSpline.curve.getPhysicalPoint(1, editorTypes.candle).x / 2
      setSpline(newSpline, false)

      const newDisks = geometryState.disks.map((disk) => disk.getScaled(radius))
      geometryState.setDisks(newDisks, false)
    }
  }

  const onHeightChange = (value: number) => {
    const knots = [...spline.knots]
    knots[0].position.y = 0
    knots[knots.length - 1].position.y = (value * 10) / printbedSize.z
    setSpline(
      new Spline(knots, spline.modelType, spline.overhangAngleDegrees),
      false
    )
  }

  return (
    <Flex direction={'column'} alignItems={'center'} width={'80%'}>
      {geometryState.modelType.flags.includes(
        modelTypeFlags.showWidthInSplineScaler
      ) && (
        <SliderContainer
          geometryState={geometryState}
          modelBeingUpdated={websiteState.modelBeingUpdated}
          setModelBeingUpdated={websiteState.setModelBeingUpdated}
          numerical={websiteState.numericalMode}
          defaultvalue={4}
          pickerstyles={pickerStyles}
          text={'WIDTH'}
          min={minWidth ? (minWidth * smallerWidthDimension) / 10 : 0.2}
          max={smallerWidthDimension / 10}
          value={(spline.knots[0].position.x * smallerWidthDimension) / 10}
          onChange={onWidthChange}
          step={0.01}
        />
      )}
      {geometryState.modelType.flags.includes(
        modelTypeFlags.showHeightInSplineScaler
      ) && (
        <SliderContainer
          geometryState={geometryState}
          modelBeingUpdated={websiteState.modelBeingUpdated}
          setModelBeingUpdated={websiteState.setModelBeingUpdated}
          numerical={websiteState.numericalMode}
          defaultvalue={4}
          pickerstyles={pickerStyles}
          text={'HEIGHT'}
          min={minHeight ? (minHeight * printbedSize.z) / 10 : 0}
          max={printbedSize.z / 10}
          value={
            ((spline.knots[1].position.y - spline.knots[0].position.y) *
              printbedSize.z) /
            10
          }
          onChange={onHeightChange}
          step={0.01}
        />
      )}
    </Flex>
  )
}
