import { Flex, useStyleConfig } from '@chakra-ui/react'
import { SliderContainer } from './new-shape-selector'
import { GeometryState, WebsiteState } from 'lib/state'
import { Spline } from 'lib/spline-classes'

export default function ConfigPanel({
  websiteState,
  geometryState,
}: {
  websiteState: WebsiteState
  geometryState: GeometryState
}) {
  const pickerStyles = useStyleConfig('FieldBox', { variant: 'pickerInput' })

  const onOverhangChange = (value: number) => {
    geometryState.setSpline(
      new Spline(
        geometryState.spline.knots,
        geometryState.spline.modelType,
        value
      )
    )
  }

  return (
    <Flex direction={'column'} alignItems={'center'} width={'80%'}>
      <SliderContainer
        geometryState={geometryState}
        modelBeingUpdated={websiteState.modelBeingUpdated}
        setModelBeingUpdated={websiteState.setModelBeingUpdated}
        numerical={websiteState.numericalMode}
        defaultvalue={40}
        pickerstyles={pickerStyles}
        text={'OVERHANG'}
        min={10}
        max={80}
        value={geometryState.spline.overhangAngleDegrees}
        onChange={onOverhangChange}
        step={0.01}
      />
    </Flex>
  )
}
