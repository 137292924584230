import { Flex, useStyleConfig } from '@chakra-ui/react'
import { constants } from 'lib/constants'
import { GeometryState, WebsiteState } from 'lib/state'
import { useEffect } from 'react'
import { SliderContainer } from './new-shape-selector'

/*
 * Just a slider to resize the currently selected sphere
 */

interface TaperScalerProps {
  geometryState: GeometryState
  websiteState: WebsiteState
}

export default function TaperScaler({
  geometryState,
  websiteState,
}: TaperScalerProps) {
  const pickerStyles = useStyleConfig('FieldBox', { variant: 'pickerInput' })

  const onTopLipWidthChange = (value: number) => {
    geometryState.setAdditionalOptions((currentValue) => {
      const newValue = { ...currentValue }
      newValue.topLipWidth = value
      return newValue
    }, false)
  }

  const onBottomLipWidthChange = (value: number) => {
    geometryState.setAdditionalOptions((currentValue) => {
      const newValue = { ...currentValue }
      newValue.bottomLipWidth = value
      return newValue
    }, false)
  }

  const onOcclusionChange = (value: number) => {
    geometryState.setSpheres((currentValue) => {
      const newValue = [...currentValue]
      newValue.forEach((item) => (item.occlusion = value))
      return newValue
    }, false)
  }

  return (
    <Flex direction={'column'} alignItems={'center'} width={'80%'}>
      <SliderContainer
        geometryState={geometryState}
        modelBeingUpdated={websiteState.modelBeingUpdated}
        setModelBeingUpdated={websiteState.setModelBeingUpdated}
        numerical={websiteState.numericalMode}
        defaultvalue={20}
        pickerstyles={pickerStyles}
        text={'ROUNDED TOP'}
        min={0}
        max={20}
        value={geometryState.additionalOptions.topLipWidth}
        onChange={onTopLipWidthChange}
        step={0.1}
      />
      <SliderContainer
        geometryState={geometryState}
        modelBeingUpdated={websiteState.modelBeingUpdated}
        setModelBeingUpdated={websiteState.setModelBeingUpdated}
        numerical={websiteState.numericalMode}
        defaultvalue={20}
        pickerstyles={pickerStyles}
        text={'ROUNDED BOTTOM'}
        min={0}
        max={20}
        value={geometryState.additionalOptions.bottomLipWidth}
        onChange={onBottomLipWidthChange}
        step={0.01}
      />
      {geometryState.spheres && geometryState.spheres.length > 0 && (
        <SliderContainer
          geometryState={geometryState}
          modelBeingUpdated={websiteState.modelBeingUpdated}
          setModelBeingUpdated={websiteState.setModelBeingUpdated}
          numerical={websiteState.numericalMode}
          defaultvalue={0.6}
          pickerstyles={pickerStyles}
          text={'BUBBLE PEAK'}
          min={0.5}
          max={0.8}
          value={geometryState.spheres[0].occlusion}
          onChange={onOcclusionChange}
          step={0.01}
        />
      )}
    </Flex>
  )
}
