import { TbWaveSine } from 'react-icons/tb'
import { MdDeleteOutline } from 'react-icons/md'
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useStyleConfig,
  Box,
} from '@chakra-ui/react'
import { useSize } from '@chakra-ui/react-use-size'
import { MutableRefObject, useRef } from 'react'
import { GoChevronDown } from 'react-icons/go'
import { constants } from 'lib/constants'
import { SliderContainer } from './new-shape-selector'
import { GeometryState, WebsiteState } from 'lib/state'
import { SurfaceModifierType } from 'lib/shape-classes'
import { CheckIcon } from '@chakra-ui/icons'

interface SurfaceStructureProps {
  geometryState: GeometryState
  websiteState: WebsiteState
}

export default function SurfaceStructurePicker({
  geometryState,
  websiteState,
}: SurfaceStructureProps) {
  const menuButtonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null)
  const menuButtonDimensions = useSize(menuButtonRef)
  const pickerStyles = useStyleConfig('FieldBox', { variant: 'pickerInput' })

  const onOptionChange = (value: string) => {
    if (
      value == constants.surfaceModifierNames.sinusoid ||
      value == constants.surfaceModifierNames.sinusoidAlt
    ) {
      geometryState.setSurfaceModifier({
        name: value,
        amplitude: geometryState.surfaceModifier.amplitude
          ? geometryState.surfaceModifier.amplitude
          : 0.45,
        frequency: geometryState.surfaceModifier.frequency
          ? geometryState.surfaceModifier.frequency
          : 45,
      })
    } else if (value == constants.surfaceModifierNames.none) {
      geometryState.setSurfaceModifier({
        name: value,
      })
    }
  }

  const setFrequencyValue = (value: number) => {
    geometryState.setSurfaceModifier((curValue) => {
      const newValue = { ...curValue }
      newValue.frequency = value
      return newValue
    }, false)
  }

  const setAmplitudeValue = (value: number) => {
    geometryState.setSurfaceModifier((curValue) => {
      const newValue = { ...curValue }
      newValue.amplitude = value
      return newValue
    }, false)
  }

  const getSliders = () => {
    if (
      geometryState.surfaceModifier &&
      (geometryState.surfaceModifier.name ==
        constants.surfaceModifierNames.sinusoid ||
        geometryState.surfaceModifier.name ==
          constants.surfaceModifierNames.sinusoidAlt)
    )
      return (
        <Flex direction={'column'} alignItems={'center'} width={'80%'}>
          <SliderContainer
            geometryState={geometryState}
            modelBeingUpdated={websiteState.modelBeingUpdated}
            setModelBeingUpdated={websiteState.setModelBeingUpdated}
            numerical={websiteState.numericalMode}
            defaultvalue={4}
            pickerstyles={pickerStyles}
            text={'REPS'}
            min={8}
            max={70}
            value={geometryState.surfaceModifier.frequency}
            onChange={setFrequencyValue}
            step={1}
          />
          <SliderContainer
            geometryState={geometryState}
            modelBeingUpdated={websiteState.modelBeingUpdated}
            setModelBeingUpdated={websiteState.setModelBeingUpdated}
            numerical={websiteState.numericalMode}
            defaultvalue={0}
            pickerstyles={pickerStyles}
            text={'SIZE'}
            min={0}
            max={2.5}
            value={geometryState.surfaceModifier.amplitude}
            onChange={(value: number) => setAmplitudeValue(value)}
            step={0.05}
          />
        </Flex>
      )
  }
  return (
    <Flex direction={'column'} alignItems={'center'} width={'100%'}>
      <Menu autoSelect={false}>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              __css={pickerStyles}
              ref={menuButtonRef}
              marginBottom={'1em'}
              width={'80%'}
              _hover={{ filter: 'brightness(60%)' }}
            >
              <Flex
                hidden={!websiteState.sidebarIsOpen}
                width={'100%'}
                height={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                SURFACE MODIFIER
                <Flex alignItems={'center'}>
                  |
                  <GoChevronDown />
                </Flex>
              </Flex>
            </MenuButton>

            <MenuList
              overflowY={'auto'}
              maxH={'40vh'}
              backgroundColor={'#' + geometryState.color}
              width={
                menuButtonDimensions ? menuButtonDimensions.width : 'inherit'
              }
              zIndex={6}
            >
              <MenuItem
                _hover={{
                  fontSize: '1.5em',
                  backgroundColor: 'rgba(0,0,0,0)',
                }}
                transition='all .1s ease'
                onClick={() =>
                  onOptionChange(constants.surfaceModifierNames.none)
                }
              >
                <MdDeleteOutline />
                {constants.surfaceModifierNames.none}
                <Box
                  as={CheckIcon}
                  m={3}
                  display={
                    geometryState.surfaceModifier.name === 'none'
                      ? 'block'
                      : 'none'
                  }
                />
              </MenuItem>
              <MenuItem
                _hover={{
                  fontSize: '1.5em',
                  backgroundColor: 'rgba(0,0,0,0)',
                }}
                transition='all .1s ease'
                onClick={() =>
                  onOptionChange(constants.surfaceModifierNames.sinusoid)
                }
              >
                <TbWaveSine />
                {constants.surfaceModifierNames.sinusoid}
                <Box
                  as={CheckIcon}
                  m={3}
                  display={
                    geometryState.surfaceModifier.name === 'Wavy'
                      ? 'block'
                      : 'none'
                  }
                />
              </MenuItem>
              <MenuItem
                _hover={{
                  fontSize: '1.5em',
                  backgroundColor: 'rgba(0,0,0,0)',
                }}
                transition='all .1s ease'
                onClick={() =>
                  onOptionChange(constants.surfaceModifierNames.sinusoidAlt)
                }
              >
                <TbWaveSine />
                {constants.surfaceModifierNames.sinusoidAlt}{' '}
                <Box
                  as={CheckIcon}
                  m={3}
                  display={
                    geometryState.surfaceModifier.name === 'Woven'
                      ? 'block'
                      : 'none'
                  }
                />
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
      {getSliders()}
    </Flex>
  )
}
