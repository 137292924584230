import {
  AspectRatio,
  Box,
  Flex,
  Tooltip,
  useColorModeValue,
  useStyleConfig,
} from '@chakra-ui/react'
import styles from '../styles/SplineEditor.module.css'
import { useSize } from '@chakra-ui/react-use-size'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { IoArrowUndoCircle, IoRemoveCircle } from 'react-icons/io5'
import { config, smallerXYDimension } from 'lib/configs'
import { modelTypes } from 'lib/constants'
import { IneCatmullRom } from 'lib/spline-classes'
import { Knot } from 'lib/spline-classes'
import { isTouchDevice } from 'lib/commons'
import Draggable, {
  DraggableCore,
  DraggableData,
  DraggableEvent,
} from 'react-draggable'
import { getProfile } from './new-shape-selector'
import { IneShape } from 'lib/mesh-maker'
import { IneSpline, IneKnot } from 'lib/spline-classes'
import { GeometryState, WebsiteState } from 'lib/state'
import { Vector2 } from 'three'

//TODO remove the any type
export function DraggableKnot(props: any) {
  const positionUpdater = (
    _: DraggableEvent | undefined,
    info: DraggableData
  ) => {
    const position = { x: info.x, y: info.y }
    if (info.x < 0) {
      position.x = 0
    } else if (info.x > props.editorSize.width)
      position.x = props.editorSize.width
    if (info.y < 0) {
      position.y = 0
    } else if (info.y > props.editorSize.height)
      position.y = props.editorSize.height

    if (props.flipped) {
      position.y = props.editorSize.height - position.y
    }
    return position
  }

  const onStartHandler = () => {
    props.geometryState.putCurrentStateInPreviousStates()
    props.setActiveIndex(props.index)
    props.setModelBeingUpdated(true)
    props.setHideModifier(true)
  }

  const onStopHandler = () => {
    props.setModelBeingUpdated(false)
    props.setHideModifier(false)
  }

  const dotRadius = isTouchDevice() ? 20 + 'px' : 13 + 'px'
  return (
    <DraggableCore
      disabled={!props.isEnabled}
      onDrag={(event, info) => {
        props.updatePositionOnDrag(props.index, info, positionUpdater)
      }}
      onStart={onStartHandler}
      onStop={onStopHandler}
    >
      <div
        style={{
          position: 'absolute',
          left: props.position.x,
          top: props.position.y,
          zIndex: 2,
        }}
      >
        <Box
          width={dotRadius}
          height={dotRadius}
          borderRadius={props.type ? '50%' : '10%'}
          borderWidth={props.isEnabled ? '3px' : '0px'}
          borderColor={props.strokeColor}
          transform={'translateY(-50%) translateX(-50%)'}
          backgroundColor={
            props.isEnabled && props.activeIndex != props.index
              ? props.backgroundColor
              : props.strokeColor
          }
          _hover={{ backgroundColor: props.strokeColor }}
          zIndex={2}
        ></Box>
      </div>
    </DraggableCore>
  )
}

interface IneEditorProps {
  geometryState: GeometryState
  websiteState: WebsiteState
}

export default function IneEditor({
  geometryState,
  websiteState,
}: IneEditorProps) {
  const [profiles, setProfiles] = [
    geometryState.profiles,
    geometryState.setProfiles,
  ]
  const [spline, updateSpline] = useState(
    (getProfile(1, profiles) as IneShape).spline
  )

  function setSpline(newSpline: IneSpline) {
    updateSpline(newSpline)

    setProfiles(
      {
        startProfile: new IneShape(newSpline, 0, 0),
        endProfile: new IneShape(newSpline, 1, 0),
        intermediateShapes: [],
      },
      false
    )
  }

  const editorType = geometryState.modelType.editorType

  const printBedWidthToHeightRatio =
    editorType.getSmallerXYDimension() / editorType.printerType.dimensions.z

  const socketType = geometryState.modelType

  const editorRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const editorSize = useSize(editorRef)
  const [activeIndex, setActiveIndex] = useState(-1)

  const flipped = socketType.id == modelTypes.leroyMerlin.id

  const updatePositionOnDrag = (
    index: number,
    info: DraggableData,
    positionUpdater: (
      _: DraggableEvent | undefined,
      info: DraggableData
    ) => Vector2
  ) => {
    let currentKnots = [...spline.knots]
    let knotToUpdate = currentKnots[index]

    const restrictedPosition = positionUpdater(undefined, info)
    knotToUpdate.updatePosition(
      Knot.convertEditorPosToNormalizedPos(editorSize!, restrictedPosition)
    )

    let newSpline = new IneSpline(currentKnots, socketType)
    setSpline(newSpline)
  }

  const containerStyle = useStyleConfig('FieldBox')
  const containerWidth = 350
  const strokeColor = useColorModeValue('black', 'white')
  const backgroundColor = useColorModeValue(
    'background.light',
    'background.dark'
  )

  const dotDiameter = 13

  const undoFunction = () => {
    geometryState.undoState()
  }

  return (
    <AspectRatio
      ratio={printBedWidthToHeightRatio}
      width={'80%'}
      maxWidth={containerWidth + 'px'}
    >
      <Flex
        position={'relative'}
        __css={containerStyle}
        padding={'13px'}
        transform={flipped ? 'scaleY(-1)' : 'unset'}
      >
        <Flex
          ref={editorRef}
          position={'relative'}
          width={'100%'}
          height={'100%'}
        >
          {editorSize &&
            spline.knots.map((knot: IneKnot, index: number) => {
              /* This is for extracting the initial shape console.log(index, "new IneKnot( { x:" + knot.position.x + ", y: " + knot.position.y + "} , 0, false, " + knot.type + ", true, false)") */
              const isEnabled = knot.locked != 3
              return (
                <DraggableKnot
                  geometryState={geometryState}
                  key={index}
                  type={knot.type}
                  index={index}
                  spline={spline}
                  position={Knot.convertNormalizedPosToEditorPos(
                    editorSize,
                    knot.position
                  )}
                  updatePositionOnDrag={updatePositionOnDrag}
                  editorSize={editorSize}
                  isEnabled={isEnabled}
                  strokeColor={strokeColor}
                  backgroundColor={backgroundColor}
                  flipped={flipped}
                  isTouchDevice={isTouchDevice}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  setModelBeingUpdated={websiteState.setModelBeingUpdated}
                  setHideModifier={websiteState.setHideModifier}
                />
              )
            })}

          {
            //This is for testing and debugging only. So we can see the bezier handles and debug easily
            false &&
              editorSize &&
              spline.knots.map((knot: IneKnot, index: number) => {
                if (knot.handles.in != undefined) {
                  let inHandlePosition = Knot.convertNormalizedPosToEditorPos(
                    editorSize!,
                    knot.handles.in
                  )
                  inHandlePosition.x -= dotDiameter / 2
                  inHandlePosition.y -= dotDiameter / 2
                  return (
                    <Draggable
                      onStart={() => false}
                      key={index}
                      bounds='parent'
                      position={inHandlePosition}
                    >
                      <div
                        className={styles.dragBox}
                        style={{
                          width: dotDiameter + 'px',
                          height: dotDiameter + 'px',
                          backgroundColor: 'yellow',
                          opacity: 0.3,
                          zIndex: 1,
                        }}
                      ></div>
                    </Draggable>
                  )
                }
              })
          }

          {
            //This is for testing and debugging only. So we can see the bezier handles and debug easily
            false &&
              editorSize &&
              spline.knots.map((knot: IneKnot, index: number) => {
                if (knot.handles.out != undefined) {
                  let outHandlePosition = Knot.convertNormalizedPosToEditorPos(
                    editorSize!,
                    knot.handles.out
                  )
                  outHandlePosition.x -= dotDiameter / 2
                  outHandlePosition.y -= dotDiameter / 2
                  return (
                    <Draggable
                      onStart={() => false}
                      key={index}
                      bounds='parent'
                      position={outHandlePosition}
                    >
                      <div
                        className={styles.dragBox}
                        style={{
                          width: dotDiameter + 'px',
                          height: dotDiameter + 'px',
                          backgroundColor: 'green',
                          opacity: 0.3,
                          zIndex: 1,
                        }}
                      ></div>
                    </Draggable>
                  )
                }
              })
          }

          {editorSize && spline.curve && (
            <svg height='100%' width='100%' className={styles.svgContainer}>
              <polyline
                className={styles.spline}
                style={{ stroke: strokeColor }}
                points={spline ? spline.getPolylineString(editorSize) : ''}
              />
            </svg>
          )}
        </Flex>
        <Box
          position={'absolute'}
          bottom={flipped ? 'unset' : '1em'}
          right={'1em'}
          top={flipped ? '1em' : 'unset'}
          transform={flipped ? 'scaleY(-1)' : 'unset'}
          zIndex={1}
          color={strokeColor}
          onClick={undoFunction}
        >
          <IoArrowUndoCircle
            fontSize={websiteState.isLandscape ? '2em' : '3.5em'}
          />
        </Box>
      </Flex>
    </AspectRatio>
  )
}
